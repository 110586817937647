import { $post } from '@/api/http.js'
import common from "@/api/common.js"
import tool from '@/util/tool.js'
import moment from "moment"

class User{
    /**
     * 获取积分记录列表
     * @param {number} page     当前页面
     * @param {number} limit    每页显示条数
     * @param {number} user_id  用户UID
     * @returns 
     */
    getScoreRecord(page,limit,user_id){
        return new Promise(reslove=>{
            let param = { page,limit }
            if( user_id ) param.user_id = user_id
            common.getDataList("scoreLogList",param).then(res=>{
                reslove(res)
            })
        })
    }

    /**
     * 获取应用端用户列表信息
     * @param {number} page 
     * @param {number} limit 
     * @param {object} extra 
     * @param {function} fn 
     */
    getAccount(page,limit,extra,fn){
        let param = {page,limit}
        if( extra){
            if( extra.sort ) param.sort = extra.sort
            if( extra.key ) param.key = extra.key
            if( extra.user_id ) param.user_id = extra.user_id
            if( extra.searchTime && extra.searchTime.length > 0 ){
                param.start_time = moment(extra.searchTime[0]).format("YYYY-MM-DD")
                param.end_time = moment(extra.searchTime[1]).format("YYYY-MM-DD")
            }
        }
        common.getDataList("getAccountList",param).then(res=>fn(res))
    }

    /**
     * 获取会员钱包信息
     * @param {number} user_id 
     * @param {function} fn 
     */
    getAccountMoney(user_id,fn){
        $post('getAccountMoney',{id:user_id}).then(res=>fn(res.data.money))
    }

    /**
     * 修改会员信息
     * @param {object} param 
     * @param {function} fn 
     */
    updateAccount(param,fn){
        $post('updateAccount',param).then(res=>{
            tool.message("操作成功")
            fn(res.data)
        },()=>{})
    }
    /**
     * 修改会员发言权限
     * @param {number} user_id 
     */
    updateAccountSpeckAuth(user_id){
        $post("updateSpeakAuthByAccount",{user_id}).then(()=>{
            tool.message("操作成功")
        })
    }

    /**
     * 获取会员详情
     * @param {number} user_id 
     * @param {function} fn 
     */
    getAccountDetail(user_id,fn){
        $post("getAccountDetail",{user_id}).then(res=>fn(res.data))
    }

    /**
     * 获取会员等级信息
     * @param {number} level_id 
     * @param {function} fn 
     */
    getAccountLevelByUser(level_id,fn){
        $post('getAccountLevelDetail',{id:level_id}).then(res=>fn(res.data))
    }

    /**
     * 获取会员等级信息
     * @param {number} page 
     * @param {number} limit 
     * @param {function} fn 
     */
    getAccountLevel(page,limit,fn){
        let param = { page,limit }
        common.getDataList("getAccountLevelList",param).then(res=>{
            fn(res)
        })
    }

    /**
     * 新增或编辑会员等级
     * @param {object}} param 
     * @param {function} fn 
     */
    addOrEditAccountLevel(param,fn){
        if( !tool.returnMessage(param.level,"请选择等级")) return
        if( !tool.returnMessage(param.discount,"请输入折扣等级")) return
        if( !tool.returnMessage(param.up_price,"请输入升级条件")) return
        if( !tool.returnMessage(param.level_name,"请输入等级名称")) return
        if( !param.id ) delete param.id

        $post("createOrUpdateAccountLevel",param).then(res=>{
            tool.message("保存成功")
            fn(res.data)
        }).catch(()=>{})
    }

    /**
     * 获取员工列表数据
     * @returns 
     */
    getEmployeeList(){
        return new Promise(resolve=>{
            $post("getUserList").then(res=>{
                resolve(res.data)
            })
        })
    }

    /**
     * 获取客户用户列表信息
     * @param {string} page 
     * @param {string} limit 
     * @returns 
     */
    getServiceUserList(page,limit){
        return new Promise(reslove=>{
            let param = { page,limit }
            common.getDataList("imUserList",param).then(res=>{
                reslove(res)
            })
        })
    }

    /**
     * 添加客服
     * @param {string} sence 添加（add） 编辑（edit） 删除（delete）
     * @param {number/object} data 
     * @returns 
     */
    addOrEditServiceUser(sence,data ){
        return new Promise(resolve=>{
            switch(sence){
                case "add":
                    if( !data ){
                        tool.message("请选择要设置为客服的员工！","warning");
                        resolve(false)
                        return
                    }
                    $post("addImUser",{admin_id:data}).then(()=>{
                        tool.message("设置成功")
                        resolve(true)
                    })
                    break;
                case "edit":
                    $post("updateImUser",data).then(()=>{
                        tool.message("保存成功")
                        resolve(true)
                    })
                    break;
                case "delete":
                    tool.confirm("确认设置该用户为客服吗？").then(()=>{
                        $post("deleteImUser",{cus_id:data}).then(()=>{
                            tool.message("删除成功","success")
                            resolve(true)
                        })
                    }).catch(()=>{})
                    break;
                default:
                    break
            }
        })
    }

    /**
     * 获取当前登录的用户信息
     * @returns 
     */
    getUserDetail(user_id){
        return new Promise(resolve=>{
            let param = {}
            if( user_id) param.user_id = user_id
            $post("getUserDetail",param,true,1).then(res=>{
                resolve(res.data)
            })
        })
    }
    /**
     * 添加保存用户信息（saas端）
     * @param {object} data用户数据
     * @param {object} fn 
     */
    addOrEditChildUser(data,fn){
        $post("addOrEditChildUser",data,true,1).then(res=>{
            tool.message("保存成功")
            fn(res)
        })
    }

    /**
     * 获取交易、资金明细
     * @param {number} page 
     * @param {number} limit 
     * @param {object} extra 
     * @param {function} fn 
     */
    getMoneyTrade(page,limit,extra,fn){
        let param = { page,limit} , api = ""
        if( extra){
            if( extra.user_id ) param.id = extra.user_id
            if( extra.trade_status && extra.trade_status.length > 0 ) param.trade_status = extra.trade_status
            if( extra.time && extra.time.length > 0 ){
				param.start_time =moment(extra.time[0]).format("YYYY-MM-DD")
				param.end_time = moment(extra.time[1]).format("YYYY-MM-DD")
			}
            if( extra.type == 1) api="getMoneyTradeList" //交易明细
            if( extra.type == 2) api="getMoneyLogList" //资金明细
            
        }

        common.getDataList(api,param).then(res=>fn(res))
    }

    /**
     * 获取签到记录
     * @param {number} page 
     * @param {number} limit 
     * @param {object} extra 
     * @param {function} fn 
     */
    getSignRecord(page,limit,extra,fn){
        let param = { page,limit}
        if( extra && extra.key ) page.key = extra.key
        common.getDataList("getAccountSignList",param).then(res=>fn(res))
    }

    /**
     * 获取充值订单记录
     * @param {number} page 
     * @param {number} limit 
     * @param {object} extra 
     * @param {function} fn 
     */
    getRechargeOrder(page,limit,extra,fn){
        let param = { page,limit}
        if( extra ){
            if( extra.order_number ) param.order_number =  extra.order_number
            if( extra.account_id ) param.account_id =  extra.account_id
            if( [0,1].includes(extra.pay_status)) param.pay_status = extra.pay_status
        }
        common.getDataList("getRechargeOrderList",param).then(res=>fn(res))
    }

    /**
     * 充值订单退款操作
     * @param {number} id 
     * @param {function} fn 
     */
    refundRechargeOrder(id,fn){
        tool.confirm("确认对该订单进行退款操作吗？").then(()=>{
            $post("refundRechargeOrder",{id}).then(res=>{
                tool.message("退款成功")
                fn(res.data)
            }).catch(()=>{})
        }).catch(()=>{})
    }

    /**
     * 获取会员认证列表
     * @param {number} page 
     * @param {number} limit 
     * @param {extra} extra 
     * @param {function} fn 
     */
    getAccountAuth(page,limit,extra,fn){
        let param = { page,limit }
        if( extra && extra.status ) param.status = extra.status
        common.getDataList("getAccountReal",param).then(res=>fn(res))
    }

    /**
     * 驳回或通过会员认证审核
     * @param {object} param 
     * @param {function} fn 
     * @returns 
     */
    refuseOrPassAccountAuth(param,fn){
        let title = "确认通过该实名认证吗？"
        if( param.status == 3 ){    //拒绝审核
            title = "确认驳回该实名认证吗？"
            if( !returnMessage(param.msg,"请输入驳回申请理由")) return
        }
        tool.confirm(title).then(()=>{
            $post("examineAccountReal",param).then(res=>{
                tool.message("操作成功")
                fn(res.data)
            }) 
        }).catch(()=>{})
    }

    /**
     * 获取会员提现记录
     * @param {number} page 
     * @param {number} limit 
     * @param {object} extra platform= user会员 store多商户
     * @param {function} fn 
     */
    getAccountWithdraw(page,limit,extra,fn){
        //type 1会员 2多商户
        let param = { page,limit,type:extra.platform == 'user' ? 1 :2 }
        console.log('param',extra);
        
        if( extra ){
            if( extra.name ) param.name = extra.name
            if( extra.account ) param.account = extra.account
            if( extra.withdraw_status >=0 ) param.withdraw_status = extra.withdraw_status
        }
        common.getDataList("getAccountWithdrawList",param).then(res=>fn(res))
    }

    /**
     * 提现处理操作
     * @param {object}} param 
     * @param {function} fn 
     */
    hanldWithdraw(param,fn){
        if( param.withdraw_status == 1 ){  //同意提现
            tool.confirm("确认同意该提现申请吗？").then(()=>{
                //{ id,withdraw_status:1 ,type} param.type 1 手动提现  2实时到账
                $post('changeWithdrawStatus',param ).then(res=>{
					tool.message('操作成功');
                    fn(res.data)
				})
            }).catch(()=>{})
            return
        }

        if( param.withdraw_status == 2){ //拒绝提现
            if( !tool.returnMessage(param.remark,'请填写拒绝提现理由')) return
			//开始拒绝提现操作
			$post('changeWithdrawStatus',param ).then(res=>{
				tool.message('操作成功');
				fn(res.data)
			})
        }
    }

    /**
     * 获取当前应用的钱包信息
     * @param {function}} fn 
     */
    getProgramMoney(fn){
        let program_id = localStorage.getItem('program_id')
		$post('getProgramMoney',{id:program_id}).then(res=>fn(res.data.money))
    }

    /**
     * 获取账单记录
     * @param {number} page 
     * @param {number} limit 
     * @param {object} extra 
     * @param {function} fn 
     */
    getBillRecord(page,limit,extra,fn){
        let param = { page,limit}
        if( extra ){
            if( extra.money_id ) param.id = extra.money_id
            if( extra.time.length > 0 ){
                param.start_time = moment(extra.time[0]).format("YYYY-MM-DD")
                param.end_time = moment(extra.time[1]).format("YYYY-MM-DD")
            }
        }
        common.getDataList("getMoneyLogList",param).then(res=>fn(res))
    }

    /**
     * 获取线下充值记录
     * @param {number} page 
     * @param {number} limit 
     * @param {function} fn 
     */
    getOfflineRecharge(page,limit,fn){
        common.getDataList("offlinePaymentList",{page,limit}).then(res=>fn(res))
    }

    /**
     * 线下充值操作
     * @param {object} param 
     * @param {function} fn 
     * @returns 
     */
    handleOfflineRechage(param,fn){
        if( !tool.returnMessage(param.status,'请选择充值处理状态')) return
        if( param.status == 2 ){
            if( !tool.returnMessage(param.msg,'请填写拒绝原因')) return
        }
        $post("editOfflinePayment",param).then(res=>{
            tool.message("操作成功")
            fn(res.data)
        }).catch(()=>{})
    }

    /**
     * 获取资产总览统计
     * @param {function} fn 
     */
    getFundOverview(fn){
        $post('fundingOverview').then(res=>fn(res.data.money))
    }

    /**
     * 收支情况统计
     * @param {number} page 
     * @param {number} limit 
     * @param {object} extra 
     * @param {function} fn 
     */
    getTradeCountToDayList(page,limit,extra,fn){
        let param = { page,limit}
        if( extra ){
            if( extra.gold_id ) param.gold_id = extra.gold_id
            if( extra.type ) param.type = extra.type
        }
        common.getDataList("getTradeCountToDayList",param).then(res=>fn(res))
    }
}
const userModel = new User()
export default userModel